.technical-indicators-plot {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  text-align: left;
  font-family: 'Merriweather', serif;
  position: relative;
  overflow: hidden;
}

.ticker-input {
  margin: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.chart-controls {
  margin-bottom: 15px;
  text-align: left;
}

.chart-selector, .field-selector {
  margin: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.field-selector {
  margin-bottom: 15px;
}

.field-option {
  margin: 5px;
  display: inline-block;
  font-size: 10px;
}

.field-option input[type="checkbox"] {
  accent-color: black;
  transform: scale(0.8);
}

.field-option input[type="checkbox"]:checked {
  background-color: black;
  border-color: black;
}

.error-message {
  color: red;
  margin: 10px 0;
}

.tradingview-widget {
  margin: 0 auto;
  max-width: 100%;
}

.technical-indicators-plot h3 {
  margin-bottom: 15px;
  text-align: left;
  margin-top: 20px;
  font-size: 8px;
  font-weight: 400;
}

.technical-indicators-plot h3:first-of-type {
  margin-top: 10px;
}

.technical-indicators-plot h3:last-of-type {
  margin-bottom: 50px;
}

.tooltip {
  position: absolute;
  visibility: hidden;
  background: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  z-index: 1000;
  font-size: 12px;
}

.chart-container {
  overflow: visible;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
}

.chart-container:hover {
  transform: translateY(-2px) scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  z-index: 2;
}

.chart-container.shrink {
  transform: scale(0.98);
  opacity: 0.9;
}

.chart-controls select {
  font-size: 14px;
  background-color: white;
}

.chart-controls select option {
  padding: 8px;
}

.chart-controls select option:checked {
  background-color: #e6f3ff;
}

.chart-controls select:focus {
  outline: none;
  border-color: #139C8D;
  box-shadow: 0 0 3px rgba(19, 156, 141, 0.3);
}

.custom-select {
  position: relative;
  display: inline-block;
  margin-left: auto;
}

.select-header {
  padding: 2px 6px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  font-size: 8px;
  background-color: white;
  font-weight: bold;
  text-align: right;
}

.select-options {
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
  min-width: 180px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.select-option {
  display: block;
  padding: 3px 6px;
  font-size: 8px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.select-option:hover {
  background-color: #f5f5f5;
}

.select-option input[type="checkbox"] {
  margin-right: 6px;
  transform: scale(0.8);
  accent-color: #1D3557;
  cursor: pointer;
}

.select-option input[type="checkbox"]:checked {
  background-color: #1D3557;
  border-color: #1D3557;
}

.select-option input[type="checkbox"]:hover {
  opacity: 0.9;
}

/* Ruler lines for selected data points */
.ruler-line {
  position: absolute;
  pointer-events: none;
  z-index: 1000;
  opacity: 0.5;
}

.ruler-line.vertical {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  border-left: 1px dashed rgba(0, 0, 0, 0.3);
  height: 100%;
  top: 0;
  transform: translateX(-50%);
}

.ruler-line.horizontal {
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  border-top: 1px dashed rgba(0, 0, 0, 0.3);
  width: 100%;
  left: 0;
  transform: translateY(-50%);
}

.point-indicator {
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #1D3557;
  border: 1px solid white;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 1001;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
}
